<template>
  <v-container class="px-0" fluid>
    <div class="d-flex align-center mb-7">
      <div v-if="!pictureFile" class="upload-background d-flex align-center justify-center" @click="$refs.logo_container.click()">
        <input ref="logo_container" type="file" accept=".jpg, .jpeg, .png" style="width: 0; height: 0" @change="handle_file_upload">
        <v-icon size="18" color="66788e">
          mdi-upload-outline
        </v-icon>
      </div>
      <div v-else class="upload-background d-flex align-center justify-center">
        <img ref="logo_preview" style="max-width: 92px; max-height: 92px;">
        <v-icon size="24" color="warning" style="position: absolute" @click="removePicture">
          mdi-close
        </v-icon>
      </div>
      <div>
        <div class="title-text mb-1">
          Image
        </div>
        <div class="text-field-hint">
          Choose profile image that will be visibile to other users
        </div>
      </div>
    </div>
    <validation-observer ref="textInput">
      <v-row class="py-0">
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Title / Salutation"
            placeholder="Mr/Mrs"
            :value="contact.salutation"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'salutation')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Suffix / Credentials"
            placeholder="Jr., Sr., MD, NP"
            :value="contact.credentials"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'credentials')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            header="Gender"
            placeholder="Select an option"
            :items="[
              { label: 'Male', value: 'M' },
              { label: 'Female', value: 'F' },
              { label: 'Other', value: 'O' },
            ]"
            :value="contact.gender"
            @input="(val) => $emit('input', val, 'gender')"
          />
        </v-col>
      </v-row>

      <v-row class="py-0">
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="First Name"
            :value="contact.firstName"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'firstName')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Middle Name"
            :value="contact.middleName"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'middleName')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Last Name"
            :value="contact.lastName"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'lastName')"
          />
        </v-col>
      </v-row>

      <v-divider class="mt-6 mb-6" />

      <v-row class="py-0">
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            header="Is Test Contact"
            placeholder="Select an option"
            :items="[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]"
            :value="contact.isTest"
            @input="(val) => $emit('input', val, 'isTest')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Contact Type"
            :value="contact.contactType"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'contactType')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            v-model="selectedSource"
            header="Source"
            placeholder="Select an option"
            :items="available_sources"
            @change="(val) => $emit('input', val, 'source')"
          />
        </v-col>
      </v-row>
      <v-row v-if="selectedAccount.accountType === 'HCP'" class="py-0">
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="NPI"
            :value="contact.npi"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'npi')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Specialty"
            :value="contact.specialty"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'specialty')"
          />
        </v-col>
      </v-row>

      <v-divider class="mt-6 mb-6" />

      <v-row class="py-0">
        <v-col v-for="field in customFields" :key="field.fieldName" cols="12" sm="4" class="py-0">
          <CustomTextInput
            v-if="['text', 'number'].includes(field.editType)"
            :header="field.name"
            :value="contact.customFields[field.fieldName]"
            :maxlength="150"
            :required="field.required"
            @input="(val) => $emit('input', val, 'customFields', field.fieldName)"
          />
          <CustomDropdown
            v-if="['choice', 'database', 'yes_or_no'].includes(field.editType)"
            :header="field.name"
            :value="customFieldDropdowns[field.fieldName]"
            :items="field.editChoices"
            :required="field.required"
            @change="(val) => handleCustomFieldDropdownChange(val, field.fieldName, false)"
          />
          <CustomTextInput
            v-if="customFieldDropdowns[field.fieldName] === '___ADD_NEW_VALUE___'"
            :header="'New Value for ' + field.name"
            :required="field.required"
            @input="(val) => handleCustomFieldDropdownChange(val, field.fieldName, true)"
          />
          <CustomDatePicker
            v-if="field.editType === 'date'"
            :header="field.name"
            :date.sync="contact.customFields[field.fieldName]"
            :required="field.required"
            @input="(val) => $emit('input', val, 'customFields', field.fieldName)"
          />
        </v-col>
      </v-row>
    </validation-observer>

    <v-row class="mt-12">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="nextStep()"
        >
          Next
          <v-icon class="pl-1" size="15">
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomDatePicker from "@/sharedComponents/CustomDatePicker";
import {parseImageFileToBase64} from "@/utils/helper";

export default {
  name: "Step1",
  components: {
    CustomDatePicker,
    CustomTextInput,
    CustomDropdown,
  },
  props: {
    contact: {
      default: () => {},
    },
  },
  data: () => {
    return {
      available_sources: [],
      selectedSource : null,
      pictureFile: null,
      customFields: null,
      customFieldDropdowns: {},
    };
  },
  computed: {
    selectedAccount() {
      return this.$store.getters['user/account'];
    },
  },
  created() {
    this.get_available_sources();
    this.getEditableCustomFields();
  },
  methods: {
    async handle_file_upload(event) {
      let file = null;
      if (event.target.files.length) {
        file = event.target.files[0];
      }

      if (!(file instanceof File)) {
        this.pictureFile = null;
        this.$refs.logo_preview.src = '';
        this.$emit('input', this.pictureFile, 'pictureFile');
        return;
      }

      parseImageFileToBase64(file).then(imgLoaded => {
        this.pictureFile = {
          data: imgLoaded.data,
          fileName: imgLoaded.name,
        };
        this.$nextTick(() => {
          this.$refs.logo_preview.src = imgLoaded.data;
        })
      }).catch(() => {
        this.pictureFile = null;
        this.$refs.logo_preview.src = '';
      }).finally(() => {
        this.$emit('input', this.pictureFile, 'pictureFile');
      });
    },
    removePicture() {
      this.pictureFile = null;
      this.$refs.logo_preview.src = '';
      this.$emit('input', this.pictureFile, 'pictureFile');
    },
    async validate() {
      return await this.$refs.textInput.validate();
    },
    reset() {
      this.$refs.textInput.reset();
    },
    resetValidation() {
      this.$refs.textInput.resetValidation();
    },
    async nextStep() {
      try {
        if (await this.validate()) {
          this.$emit('next-step')
        }
      } catch (e) {
        console.error(e);
      }
    },
    async get_available_sources(){
      let sourceData = (await this.$rest.source.get_collection()).data.items;
      this.available_sources = sourceData.map(i => ({label: i.name, value: i.id}));

      this.defaultSource = sourceData.find( ({ isDefault }) => isDefault === true ).id;
      if (!this.selectedSource) {
        this.selectedSource = this.defaultSource;
        this.$emit('input', this.selectedSource, 'source')
      }
    },
    is_custom_fields_exist (value) {
      if (value === undefined || value === null || value === '') {
        return false
      } else {
        return true;
      }
    },
    async getEditableCustomFields() {
      this.customFields = (await this.$rest.contact.getEditableCustomFields()).data.map((item) => ({
        ...item,
        required:  item.validationConstraints.find((validationItem) => validationItem.constraint === 'NotBlank'),
      }));
      this.customFields.forEach((field) => {
        if (['choice', 'database', 'yes_or_no'].includes(field.editType)) {
          this.$set(this.customFieldDropdowns, field.fieldName, this.is_custom_fields_exist(this.contact.customFields[field.fieldName]) ? this.contact.customFields[field.fieldName] : field.defaultValue);
        }
        if (!this.is_custom_fields_exist(this.contact.customFields[field.fieldName])) {
          this.$emit('input', field.defaultValue, 'customFields', field.fieldName);
        }
      });
    },
    handleCustomFieldDropdownChange(val, fieldName, isNewValueTextBox) {
      if (!isNewValueTextBox) {
        this.$set(this.customFieldDropdowns, fieldName, val);
      }

      if (val === '___ADD_NEW_VALUE___') {
        this.$emit('input', '', 'customFields', fieldName);
      } else {
        this.$emit('input', val, 'customFields', fieldName);
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.upload-background {
  cursor: pointer;
  width: 95px;
  min-width: 95px;
  height: 95px;
  margin: 0 14px 0 0;
  border-radius: 3px;
  background-color: #ecf1f5;
  * {
    cursor: pointer;
  }
}

::v-deep .custom-text-input .field__subheader,
::v-deep .custom-date-picker .field__subheader {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #241c15;
}
</style>
