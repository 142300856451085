<template>
  <v-stepper class="elevation-0 pulse-stepper" :value="value" alt-labels>
    <v-stepper-header class="d-flex justify-end">
      <v-stepper-step step="1" />
      <v-divider />
      <v-stepper-step step="2" />
      <v-divider />
      <v-stepper-step step="3" />
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  name: "CustomStepper",
  props: {
    value: {
      default: () => {},
    },
  },
  computed: {
    // step: {
    //   get() {
    //     return this.value;
    //   },
    //   set(val) {
    //     this.$emit("");
    //   },
    // },
  },
};
</script>

<style lang="scss" scoped>
.pulse-stepper {
  hr {
    display: none;
    width: 0px;
    margin: 0 !important;
  }
  ::v-deep .v-stepper__step {
    max-width: 32px;
    padding-top: 10px;
    padding-bottom: 0;
    font-weight: bold;

    &--inactive {
      span {
        background-color: #e5eaed !important;
        color: #a5a9b8 !important;
      }
    }
    &--active {
      span {
        background-color: #2b84eb !important;
        color: white !important;
      }
    }
    span {
      width: 31px;
      height: 31px;
      font-size: 14px;
    }
  }
}
</style>
