<template>
  <CustomDropdown
    :header="header"
    placeholder="Select an option"
    :items="options"
    :value="value"
    @input="(val) => $emit('input', val)"
  />
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";
export default {
  name: "StateSelect",
  components: {
    CustomDropdown,
  },
  props: {
    value: {
      default: () => null,
    },
    selected_country: {
      default: () => null,
    },
    header: {
      default: () => 'State',
    },
    useValueAsLabel: {
      default: false,
    },
  },
  data() {
    return {
      Canada_provinces_list: [
        {
          label: 'Alberta',
          value: 'AB'
        },
        {
          label: 'British Columbia',
          value: 'BC'
        },
        {
          label: 'Manitoba',
          value: 'MB'
        },
        {
          label: 'New Brunswick',
          value: 'NB'
        },
        {
          label: 'Newfoundland and Labrador',
          value: 'NL'
        },
        {
          label: 'Northwest Territories',
          value: 'NT'
        },
        {
          label: 'Nova Scotia',
          value: 'NS'
        },
        {
          label: 'Nunavut',
          value: 'NU'
        },
        {
          label: 'Ontario',
          value: 'ON'
        },
        {
          label: 'Prince Edward Island',
          value: 'PE'
        },
        {
          label: 'Quebec',
          value: 'QC'
        },
        {
          label: 'Saskatchewan',
          value: 'SK'
        },
        {
          label: 'Yukon',
          value: 'YT'
        },
      ].sort((a, b) => {
        const aVal = this.useValueAsLabel ? a.value : a.label;
        const bVal = this.useValueAsLabel ? b.value : b.label;
        return aVal.localeCompare(bVal);
      }),
      USA_states_list: [
        {
          label: 'Alabama',
          value: 'AL'
        },
        {
          label: 'Alaska',
          value: 'AK'
        },
        {
          label: 'Arizona',
          value: 'AZ'
        },
        {
          label: 'Arkansas',
          value: 'AR'
        },
        {
          label: 'California',
          value: 'CA'
        },
        {
          label: 'Colorado',
          value: 'CO'
        },
        {
          label: 'Connecticut',
          value: 'CT'
        },
        {
          label: 'Delaware',
          value: 'DE'
        },
        {
          label: 'Florida',
          value: 'FL'
        },
        {
          label: 'Georgia',
          value: 'GA'
        },
        {
          label: 'Hawaii',
          value: 'HI'
        },
        {
          label: 'Idaho',
          value: 'ID'
        },
        {
          label: 'Illinois',
          value: 'IL'
        },
        {
          label: 'Indiana',
          value: 'IN'
        },
        {
          label: 'Iowa',
          value: 'IA'
        },
        {
          label: 'Kansas',
          value: 'KS'
        },
        {
          label: 'Kentucky',
          value: 'KY'
        },
        {
          label: 'Louisiana',
          value: 'LA'
        },
        {
          label: 'Maine',
          value: 'ME'
        },
        {
          label: 'Maryland',
          value: 'MD'
        },
        {
          label: 'Massachusetts',
          value: 'MA'
        },
        {
          label: 'Michigan',
          value: 'MI'
        },
        {
          label: 'Minnesota',
          value: 'MN'
        },
        {
          label: 'Mississippi',
          value: 'MS'
        },
        {
          label: 'Missouri',
          value: 'MO'
        },
        {
          label: 'Montana',
          value: 'MT'
        },
        {
          label: 'Nebraska',
          value: 'NE'
        },
        {
          label: 'Nevada',
          value: 'NV'
        },
        {
          label: 'New Hampshire',
          value: 'NH'
        },
        {
          label: 'New Jersey',
          value: 'NJ'
        },
        {
          label: 'New Mexico',
          value: 'NM'
        },
        {
          label: 'New York',
          value: 'NY'
        },
        {
          label: 'North Carolina',
          value: 'NC'
        },
        {
          label: 'North Dakota',
          value: 'ND'
        },  
        {
          label: 'Ohio',
          value: 'OH'
        },  
        {
          label: 'Oklahoma',
          value: 'OK'
        }, 
        {
          label: 'Oregon',
          value: 'OR'
        },   
        {
          label: 'Pennsylvania',
          value: 'PA'
        },
        {
          label: 'Rhode Island',
          value: 'RI'
        },     
        {
          label: 'South Carolina',
          value: 'SC'
        },  
        {
          label: 'South Dakota',
          value: 'SC'
        },  
        {
          label: 'Tennessee',
          value: 'TN'
        },                        
        {
          label: 'Texas',
          value: 'TX'
        },                    
        {
          label: 'Utah',
          value: 'UT'
        }, 
        {
          label: 'Vermont',
          value: 'VT'
        }, 
        {
          label: 'Virginia',
          value: 'VA'
        }, 
        {
          label: 'Washington',
          value: 'WA'
        }, 
        {
          label: 'West Virginia',
          value: 'WV'
        }, 
        {
          label: 'Wisconsin',
          value: 'WI'
        }, 
        {
          label: 'Wyoming',
          value: 'WY'
        }, 
        {
          label: 'Puerto Rico',
          value: 'PR'
        }, 
      ].sort((a, b) => {
        const aVal = this.useValueAsLabel ? a.value : a.label;
        const bVal = this.useValueAsLabel ? b.value : b.label;
        return aVal.localeCompare(bVal);
      }),
    }
  },
  computed: {
    options() {
      let options = this.selected_country === 'CAN'
          ? this.Canada_provinces_list
          : this.USA_states_list;

      return (this.useValueAsLabel
          ? options.map(item => ({label: item.value, value: item.value}))
          : options
      ).sort((a, b) => {
        if (a.label < b.label) { return -1; }
        if (a.label > b.label) { return 1; }
        return 0;
      });
    },
  },
};
</script>

