<template>
  <CustomDropdown
    :header="hasHeader ? 'Country' : null"
    placeholder="Select an option"
    :items="[
      { label: 'USA', value: 'USA' },
      { label: 'Canada', value: 'CAN' },
    ]"
    :value="value"
    @input="(val) => $emit('input', val)"
  />
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";
export default {
  name: "CountrySelect",
  components: {
    CustomDropdown,
  },
  props: {
    hasHeader: {
      type: Boolean,
      default: true,
    },
    value: {
      default: () => null,
    },
  },
};
</script>
