import { render, staticRenderFns } from "./CustomStepper.vue?vue&type=template&id=2d7310f4&scoped=true"
import script from "./CustomStepper.vue?vue&type=script&lang=js"
export * from "./CustomStepper.vue?vue&type=script&lang=js"
import style0 from "./CustomStepper.vue?vue&type=style&index=0&id=2d7310f4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d7310f4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VDivider,VStepper,VStepperHeader,VStepperStep})
