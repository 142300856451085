<template>
  <v-container class="mt-3 px-sm-10 px-3" fluid>
    <PageHeader
      :header-text="
        currentStep === 1 ? 'Add Contact' : 'Add Individual Contact'
      "
    >
      <template #subheader>
        <div
          class="pb-8"
        >
          Create a new contact by filling out the information below
        </div>
      </template>
    </PageHeader>
    <v-divider />

    <v-row class="pa-0 mt-4 flex-nowrap contact">
      <v-col cols="12" sm="8" md="9" lg="10">
        <div class="contact__title-text pt-2">
          Email address
        </div>
        <div class="contact__subtitle-text">
          Get started by providing an email address
        </div>
        <validation-observer ref="contactEmail">
          <div class="input-with-button d-flex align-center">
            <validation-provider
              v-slot="{ errors }"
              class="flex-grow-1"
              style="max-width: 400px"
              rules="required|email"
              mode="eager"
            >
              <v-text-field
                v-model.trim="email"
                style="min-width: 260px; max-width: 400px"
                dense
                :error-messages="errors"
                :class="{ 'email--submitted': emailSubmitted }"
                height="44px"
                outlined
                :disabled="emailSubmitted"
                placeholder="Email address"
                @keypress.enter="onSubmitEmail"
              >
                <template v-if="!emailSubmitted" #append>
                  <v-btn
                    height="28px"
                    depressed
                    class="custom-button custom-button--blue"
                    @click="onSubmitEmail"
                  >
                    Submit
                  </v-btn>
                </template>
              </v-text-field>
            </validation-provider>

            <div
              v-if="!emailSubmitted"
              class="simple-text-button button--blue pl-6 pl-sm-3 pr-3"
              style="height: 44px"
              @click="skipEmailEntering"
            >
              Skip
            </div>
            <div
              v-else
              class="simple-text-button button--blue pl-6 pl-sm-3 pr-3"
              style="height: 44px"
              @click="startOver"
            >
              Start Over
            </div>
          </div>
        </validation-observer>
      </v-col>

      <v-col v-if="$vuetify.breakpoint.smAndUp" sm="4" md="3" lg="2">
        <custom-stepper v-model="currentStep" />
      </v-col>
    </v-row>

    <v-divider v-if="emailSubmitted || currentStep > 1" class="mt-5 mb-6" />

    <Step1
      v-show="currentStep === 1 && emailSubmitted"
      :contact="contact"
      @input="update_contact_field_value"
      @next-step="next_step"
    />
    <Step2
      v-show="currentStep === 2 && emailSubmitted"
      :contact="contact"
      @input="(val, fieldName) => (contact[fieldName] = val)"
      @prev-step="prev_step"
      @next-step="next_step"
    />
    <Step3
      v-show="currentStep === 3 && emailSubmitted"
      :contact="contact"
      @input="(val, fieldName) => (contact[fieldName] = val)"
      @prev-step="prev_step"
      @create-contact="on_create_contact"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomStepper from "@/sharedComponents/CustomStepper";
import Step1 from "@/views/Contacts/components/AddContact/Step1";
import Step2 from "@/views/Contacts/components/AddContact/Step2";
import Step3 from "@/views/Contacts/components/AddContact/Step3";
var _ = require('lodash');

export default {
  name: "AddSingleContact",
  components: {
    CustomStepper,
    PageHeader,
    Step1,
    Step2,
    Step3,
  },
  data() {
    return {
      email: "",
      currentStep: 1,
      emailSubmitted: false,
      blankContact: {
        // step 1
        salutation: null,
        credentials: null,
        gender: null,
        firstName: null,
        middleName: null,
        lastName: null,
        isTest: false,
        npi: null,
        specialty: null,
        contactType: null,
        source: null,
        customFields: {},
        pictureFile: null,
        // step 2
        address1: null,
        address2: null,
        city: null,
        state: null,
        zipCode: null,
        country: null,
        // step 3
        phone: null,
        fax: null,
      },
      contact: {},
    };
  },
  computed: {
    selectedAccount() {
      return this.$store.getters['user/account'];
    }
  },
  created() {
    this.contact = this.blankContact;
    this.contact.contactType = this.selectedAccount.accountType;
    const customFields = this.selectedAccount.customFieldDefinition;
    if (!customFields || !customFields.contact) {
      return;
    }

    customFields.contact.forEach((item) => {
      if (item.editType !== null) {
        return;
      }

      this.$set(this.contact.customFields, item.fieldName, item.defaultValue);
    });
  },
  methods: {
    async next_step(){
      await this.$rest.contact.validate_contact(this.get_contact_data());

      this.currentStep += 1;
    },
    prev_step(){
      this.currentStep -= 1;
    },
    skipEmailEntering() {
      this.emailSubmitted = true;
      this.email = "";
    },
    async validate() {
      return await this.$refs.contactEmail.validate();
    },
    async onSubmitEmail() {
      try {
        if (await this.validate()) {
          const contact_info = (await this.$rest.contact.check_email(this.email)).data
          if (contact_info.items.length > 0) {
            await this.$rest.contact.get_contact(contact_info.items[0].contactId);
          }
          this.emailSubmitted = true;
        } else {
          this.$notifier.error("Email address is not valid.");
        }
      } catch (e) {
        console.error(e);
      }
    },
    startOver() {
      this.$refs.contactEmail.reset();
      this.emailSubmitted = false;
      this.currentStep = 1;

      this.contact = this.blankContact;
    },
    async on_create_contact() {
      const contactData = this.get_contact_data();

      const contact = (await this.$rest.contact.post_contact(contactData)).data;
      this.$notifier.success("Successfully saved the contact.");

      await this.$router.push({
        name: "ContactDetails",
        params: {
          id: contact.id,
        },
      });
    },
    get_contact_data() {
      let contact_emails = this.email ? [{emailAddress: this.email}] : null;
      let contact_locations = [ _.omitBy({
        company: this.contact.company,
        addressLine1: this.contact.address1,
        addressLine2: this.contact.address2,
        city: this.contact.city,
        state: this.contact.state,
        postalCode: this.contact.zipCode,
        country: this.contact.country,
      }, _.isNil)];
      let contact_phones = [];
      if (this.contact.phone) {
        contact_phones.push(_.omitBy({"phone": this.contact.phone}, _.isNil));
      }
      if (this.contact.fax) {
        contact_phones.push(_.omitBy({"phone": this.contact.fax, "isFax": true}, _.isNil));
      }

      let general = {
        id: this.id,
        isTest: this.contact.isTest,
        salutation: this.contact.salutation,
        firstName: this.contact.firstName,
        middleName: this.contact.middleName,
        lastName: this.contact.lastName,
        credentials: this.contact.credentials,
        gender: this.contact.gender,
        npi: this.contact.npi || null,
        specialty: this.contact.specialty,
        emails: contact_emails,
        locations: Object.keys(contact_locations[0]).length > 0 ? contact_locations : null,
        phones: contact_phones.length > 0 ? contact_phones : null,
        customFields: this.contact.customFields,
        source: this.contact.source,
        pictureFile: this.contact.pictureFile,
      };

      return _.omitBy(general, _.isNil);
    },
    update_contact_field_value(val, fieldName, customFieldName) {
      if (customFieldName) {
        this.$set(this.contact.customFields, customFieldName, val);
      } else {
        this.contact[fieldName] = val;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.email--submitted {
  .v-input__control {
    background: #e5eaed;
    fieldset {
      border: 1px solid #dee7ee;
    }
  }
}

.contact {
  &__title-text {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #241c15;
    padding-bottom: 3px;
  }
  &__subtitle-text {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #66788e;
    padding-bottom: 10px;

    a {
      color: #2b84eb !important;
    }
  }
}
.custom-button {
  text-transform: none;
  .v-btn__content {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    letter-spacing: normal;
  }
  &--blue {
    background: #2b84eb !important;
    .v-btn__content {
      color: white;
    }
  }
  &--gray {
    background: #ecf1f5 !important;
    .v-btn__content {
      color: #66788e;
    }
  }
}

.input-with-button {
  min-width: 260px;
  width: 100%;

  ::v-deep {
    .v-text-field__details {
      display: none;
    }
    .v-text-field {
      fieldset {
        border-radius: 4px;
        border: solid 1px #dee7ee;
      }
    }
    .v-input__prepend-inner {
      margin-top: 9px !important;
      i {
        color: #2b84eb !important;
      }
    }
    .v-input__append-inner {
      margin-top: 5px !important;
    }
    .v-input__slot {
      padding-right: 5px !important;
      margin-bottom: 0 !important;
    }
    .v-input--is-disabled {
      background-color: #e5eaed;
      color: #66788e;
    }
    input {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #9f9fb0;
    }
  }
}
</style>
