<template>
  <v-container class="px-0" fluid>
    <validation-observer ref="textInput">
      <v-row class="py-0">
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Company"
            :value="contact.company"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'company')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Address 1"
            :value="contact.address1"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'address1')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Address 2"
            :value="contact.address2"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'address2')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="City"
            :value="contact.city"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'city')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <StateSelect
            :value="contact.state"
            :selected_country="contact.country"
            @input="(val) => $emit('input', val, 'state')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Zip Code"
            :value="contact.zipCode"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'zipCode')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CountrySelect
            :value="contact.country"
            @input="(val) => $emit('input', val, 'country')"
          />
        </v-col>
      </v-row>
    </validation-observer>

    <v-row class="mt-12">
      <v-col cols="12" class="d-flex justify-end flex-wrap">
        <v-btn
          class="custom-button custom-button--gray px-11 mr-sm-5 mb-4 mb-sm-0"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="$emit('prev-step')"
        >
          <v-icon class="pr-1" size="15">
            mdi-arrow-left
          </v-icon>
          Previous
        </v-btn>
        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="nextStep()"
        >
          Next
          <v-icon class="pl-1" size="15">
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CountrySelect from "@/sharedComponents/CountrySelect";
import StateSelect from "@/sharedComponents/StateSelect";

export default {
  name: "Step2",
  components: {
    CustomTextInput,
    CountrySelect,
    StateSelect,
  },
  props: {
    contact: {
      default: () => {},
    },
  },
  methods: {
    async validate() {
      return await this.$refs.textInput.validate();
    },
    reset() {
      this.$refs.textInput.reset();
    },
    resetValidation() {
      this.$refs.textInput.resetValidation();
    },
    async nextStep() {
      try {
        if (await this.validate()) {
          this.$emit('next-step')
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style scoped>
::v-deep .custom-text-input .field__subheader,
::v-deep .custom-date-picker .field__subheader {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #241c15;
}
</style>
